<template>
  <div class="p-3 sku-exceptions-holder">
    <header class="mb-4">
      <BackButton class="exception-BackButton"></BackButton>
      <h3 v-if="!isReplacementOperation()">Create Order</h3>
      <h3 v-else>Create replacement order for order ID {{ $route.params.orderID }}</h3>
    </header>
    <create-manual-order></create-manual-order>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import CreateManualOrder from '@/views/OpsSearchProgram/partials/NewManualOrder.vue';

export default {
  name: 'CreateManualOrder.vue',
  components: {
    'create-manual-order': CreateManualOrder,
    BackButton,
  },
  methods: {
    isReplacementOperation() {
      return this.$route.query.op === 'replacement';
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/OrderManagement.scss';
</style>
