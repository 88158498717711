<template>
  <b-card class="mb-4 border-0">
    <template #header>
      <strong>Create Order</strong>
    </template>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <form class="manual-order-div" @submit.prevent="checkForm" method="post">
        <b-row class="mb-4">
          <b-col v-if="isReplacementOperation()" cols="3">
            <b-form-group
              id="ig-replaced-order-id"
              label="Replaced Order ID"
              label-for="input-replaced-order-id">
              <b-form-input id="input-replaced-order-id" :disabled="true"
                            v-model="order.replaced_order_id"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Order Type *"
              description="Select an order type"
              label-for="input-1"
              invalid-feedback="Order type is required"
              required
              :state="isValid('order.type')">
              <b-form-select v-model="order.type" :options="orderTypeOptions"
                             @change="onOrderTypeChange"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="3" v-if="!isOrderTypeSparePart">
            <b-form-group
              id="input-group-2"
              label="Kit Type *"
              description="Select a therapy"
              label-for="input-2"
              invalid-feedback="Therapy is required."
              required
              :state="isValid('order.therapy')">
              <b-form-select v-model="order.therapy" :options="therapyTypeOptions" @change="onChange"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="isOrderTypeSparePart">
            <b-form-group
              id="input-group-category"
              label="Category *"
              description="Select a category"
              label-for="input-category"
              invalid-feedback="Category is required"
              required
              :state="isValid('order.category')">
              <b-form-select v-model="order.category" :options="categoryOptions"
                             @change="onCategoryChange"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="isOrderTypeSparePart">
            <b-form-group
              id="input-group-spare-part"
              label="Spare Part*"
              description="Select a spare part"
              label-for="input-spare-part"
              invalid-feedback="Spare part is required"
              required
              :state="isValid('order.spare_part')">
              <b-form-select v-model="order.spare_part" :options="sparePartOptions"
                             @change="onSparePartChange"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-3"
              label="SKU *"
              description="Select a SKU"
              label-for="input-3"
              :invalid-feedback="skuFeedbackMessage"
              required
              :state="isValid('order.sku')">
              <b-form-select
                v-if="(!isReplacementOperation() && isValid('order.sku')) || hasNoCompatibleSKU() || !hasHighestDistribuionSku() " v-model="order.sku"
                :disabled="!isReplacementOperation()">
                <b-form-select-option :value="order.sku">{{ order.sku }}</b-form-select-option>
              </b-form-select>
              <b-form-select v-else v-model="order.sku" :options="skuOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-6"
              label="Ship Via *"
              description="Select a Ship Via"
              label-for="input-6"
              invalid-feedback="Ship Via is required"
              required
              :state="isValid('order.ship_via')">
              <b-form-select
                v-if="!isReplacementOperation() && isValid('order.ship_via')"
                v-model="order.ship_via"
                :disabled="!isReplacementOperation()">
                <b-form-select-option :value="order.ship_via"> {{ order.ship_via }}</b-form-select-option>
              </b-form-select>
              <b-form-select v-else v-model="order.ship_via" :options="shipViaOptions"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="3">
            <b-form-group
              id="input-group-4"
              label="Reason *"
              description="Select a reason from above for the creation of this order"
              label-for="input-4"
              invalid-feedback="Reason is required"
              required
              :state="isValid('order.reason')">
              <b-form-select v-model="order.reason" :options="reasonTypeOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-7"
              label="Zendesk ticket URL"
              description="Fill with a Zendesk ticket URL"
              label-for="input-7">
              <input type="url"
                     v-model="order.support_ticket_url"
                     class="form-control">
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="order.reason === 'other'|| order.reason === 'hardware' || order.reason === 'software'" class="mb-4">
          <b-col cols="6">
            <b-form-group
              id="input-group-5"
              description="Please provide further details as to what is the reason for creating this order"
              label-for="input-5"
              invalid-feedback="Required"
              required
              :state="isValid('order.reason_description')">
              <b-form-textarea
                type="v-textarea"
                v-model="order.reason_description"
                class="manual-order-textbox"
                size="lg"
                rows="3">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4" v-if="!isEligible">
          <b-col>
            <b-check
              v-model="order.eligibility_bypassed"
              class="text-danger">Eligibility check failed! Are you sure you want create the order?</b-check>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <button v-show="!order.add_shipping_address"
                    id="add-shipping-address-button"
                    type="button"
                    @click="toggleShippingAddress"
                    class="btn btn-info btn-fill float-left btn-manual-order">
              Add Shipping Address
            </button>
            <button v-show="order.add_shipping_address"
                    id="remove-shipping-address-button"
                    type="button"
                    @click="toggleShippingAddress"
                    class="btn btn-danger btn-fill float-left btn-manual-order">
              Remove Shipping Address
            </button>
          </b-col>
        </b-row>
        <b-row id="shipping-address-wrapper" v-show="order.add_shipping_address">
          <b-col cols="3">
            <b-form-group
              id="input-group-8"
              label="Address line 1 *"
              description="Write an address line"
              label-for="input-8"
              invalid-feedback="Address line 1 is required"
              required
              :state="isValid('order.address_line_1')">
              <input class="form-control" type="text" v-model="order.address_line_1">
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-9"
              label="Address line 2"
              description="Write an address line"
              label-for="input-9"
              :state="isValid('order.address_line_2')">
              <input class="form-control" v-model="order.address_line_2">
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-10"
              label="Zip Code *"
              description="Add a zip code"
              label-for="input-10"
              invalid-feedback="Zip Code is required"
              required
              :state="isValid('order.zip_code')">
              <input class="form-control" v-model="order.zip_code">
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-11"
              label="City *"
              description="Add a city"
              label-for="input-11"
              invalid-feedback="City is required"
              required
              :state="isValid('order.city')">
              <input class="form-control" v-model="order.city">
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-13"
              label="Country *"
              description="Select a valid country"
              label-for="input-13"
              invalid-feedback="Country is required"
              required
              :state="isValid('order.country')">
              <b-form-select v-model="order.country" :options="countryOptions" @change="changeCountry"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3" v-show="showStates">
            <b-form-group
              id="input-group-12"
              label="State *"
              description="Select a state"
              label-for="input-12"
              invalid-feedback="State is required"
              required
              :state="isValid('order.state')">
              <b-form-select v-model="order.state" :options="USAStates"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col class="d-flex justify-content-end">
            <button
              id="submit-patient-search"
              type="submit"
              class="btn btn-info btn-fill float-left btn-manual-order btn-manual-order--confirm"
              :disabled="isConfirmDisabled">
              <b-spinner small v-if="saving || loading"></b-spinner>
              <span v-else>Confirm</span>
            </button>
            <button
              id="submit-patient-search-goback"
              type="button"
              class="btn btn-fill btn-manual-order btn-manual-order--cancel"
              @click="goBack">
              Cancel
            </button>
          </b-col>
        </b-row>
      </form>
    </div>
  </b-card>
</template>

<script>
import * as R from 'ramda';
import axios from 'axios';
import orderHandler from '@/mixins/orderHandler';
import { required } from 'vuelidate/lib/validators';
import { definitions } from '@sword-health/ui-core';
import translations from '@/translations';
import { PRODUCT_TYPE, UNIT_DPT, UNIT_SWORD } from '@/scripts/constants';


const countryOptions = definitions.swordCountriesList.map(country => ({
  text: country.name,
  value: country.code,
}));
const USAStates = definitions.USAStates.map(state => ({
  text: state.name,
  value: state.code,
}));
const ELIGIBILITY_COVERAGE_TYPE_DEPENDENT = 'dependent';
const ORIGIN_PORTAL = 'portal';
const OPERATION_REPLACEMENT = 'replacement';
const ManualShipViaType = 'manual';
const OrderTypeKit = 'kit';
const OrderTypeSparePart = 'spare_part';
const ProductTypeSparePart = 'spare_part';

export default {
  name: 'create-manual-order',
  data() {
    return {
      isLoading: false,
      order: {
        type: null,
        therapy: null,
        sku: null,
        reason: null,
        reason_description: null,
        ship_via: null,
        support_ticket_url: null,
        replaced_order_id: null,
        address_line_1: null,
        address_line_2: null,
        zip_code: null,
        city: null,
        state: null,
        country: null,
        add_shipping_address: false,
        category: null,
        spare_part: null,
        eligibility_bypassed: false,
      },
      patientId: 0,
      clientID: 0,
      countryStateCode: '',
      orderTypeOptions: [],
      reasonTypeOptions: [],
      therapyTypeOptions: [],
      skuOptions: [],
      shipViaOptions: [],
      reason: {},
      description: '',
      isHovered: false,
      loading: false,
      saving: false,
      countryOptions,
      USAStates,
      showStates: false,
      isEligible: false,
      isOrderTypeSparePart: false,
      categoryOptions: [],
      sparePartOptions: [],
      sparePartsSKUs: null,
      patientUnit: null,
      user: null,
    };
  },
  computed: {
    rules() {
      return {
        order: {
          type: { required },
          therapy: { required },
          sku: { required },
          reason: { required },
          reason_description: { required },
          ship_via: { required },
          address_line_1: { required },
          zip_code: { required },
          city: { required },
          state: { required },
          country: { required },
          category: { required },
          spare_part: { required },
        },
      };
    },
    isConfirmDisabled() {
      return (this.saving || (!this.isEligible && !this.order.eligibility_bypassed) || this.loading);
    },
    skuFeedbackMessage() {
      if (this.hasNoCompatibleSKU()) {
        return 'No compatible SKU’s were found';
      }

      if (!this.hasHighestDistribuionSku()) {
        return 'No valid SKU found for therapy Bloom and this patient';
      }

      return 'SKU is required';
    },
  },
  validations() {
    return this.rules;
  },
  mixins: [ orderHandler ],
  components: {},
  methods: {
    hasNoCompatibleSKU() {
      return this.checkNoCompatibleSkusForKit() || this.checkNoCompatibleSkusForSparePart();
    },
    hasHighestDistribuionSku() {
      // Execute this check only for Bloom therapy
      if (this.order.therapy !== PRODUCT_TYPE.Bloom) {
        return true;
      }
      // Check if the order sku is not empty
      return !!this.order.sku;
    },
    checkNoCompatibleSkusForKit() {
      return this.order.type === OrderTypeKit
        && !!this.order.therapy
        && this.skuOptions.length === 0;
    },
    checkNoCompatibleSkusForSparePart() {
      return this.order.type === OrderTypeSparePart
        && !!this.order.category
        && !!this.order.spare_part
        && this.skuOptions.length === 0;
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    getValidator(field) {
      const parts = field.split('.');
      let rootValidator = this.$v;

      parts.forEach(part => {
        rootValidator = rootValidator[part] || {};
      });
      return rootValidator;
    },
    isValid(field) {
      const validator = this.getValidator(field);
      const invalid = validator.$invalid;
      const value = validator.$model;

      if (!value && !('required' in validator)) {
        return true;
      }

      return !invalid;
    },
    async onChange(therapy, parentOrderSku) {
      this.loading = true;

      // Reset the sku dropdown value and its selectable options
      this.order.sku = '';
      this.skuOptions = [];

      await this.getSkus(therapy);
      await this.getHighestPrioritySKU(therapy, this.clientID, parentOrderSku, this.user.uuid);

      this.loading = false;
    },
    async getSkus(therapy) {
      const params = {
        productType: therapy,
        query: { exclude_attributes: [ 'semi_kit' ] },
      };
      const skus = await this.$store.dispatch('CSM/getSkusByProductType', params);

      if (!skus) {
        return;
      }

      this.skuOptions = skus.map(opt => ({
        text: opt.sku,
        value: opt.sku,
      }));
    },
    checkForm() {
      if (!this.isEligible && !this.order.eligibility_bypassed) {
        return false;
      }

      if (!this.order.type
        || !this.order.therapy
        || !this.order.sku
        || !this.order.reason
        || ((this.order.reason === 'other' || this.order.reason === 'hardware' || this.order.reason === 'software') && !this.order.reason_description)
        || !this.order.ship_via
        || !this.validateShippingAddressFields()) {
        return false;
      }

      return this.sendRequest(this.order, this.patientId);
    },
    goBack() {
      this.$router.go(-1);
    },
    sendRequest(order, patientId) {
      this.saving = true;
      axios
        .post('/v1/order/', { order, patientId })
        .then(() => {
          this.saving = false;
          this.$noty.success('Order created');
          this.goBack();
        })
        .catch(error => {
          this.saving = false;
          const errorMessage = error.response?.data?.message ?? 'Unable to create order';

          this.$noty.error(errorMessage);
          this.goBack();
        });
      return true;
    },
    async getHighestPrioritySKU(productTypeKey, clientID, parentOrderSku, patientUUID) {
      try {
        const res = await axios.get(`/v1/skus/highest-distribution/${productTypeKey}/${clientID}?patientUUID=${patientUUID}`);

        if (!res.data.sku && parentOrderSku && this.isSkuAvailable(parentOrderSku)) {
          this.order.sku = parentOrderSku;
          return;
        }

        this.order.sku = res.data.sku;
      } catch (err) {
        const messageError = R.path([ 'response', 'data', 'message' ], err);

        if (messageError) {
          this.order.sku = '';
          this.skuOptions = [];

          if (messageError === 'therapy not found') {
            this.$noty.error('Member does not have therapy defined, check if client has CV enabled.');
            return;
          }

          this.$noty.error(messageError);
        }
        console.error(err);
      }
    },
    async getProductTypes() {
      const productTypes = await this.$store.dispatch('OrderManagement/getProductTypes');

      return productTypes
        .filter(({ productTypeKey }) => productTypeKey !== ProductTypeSparePart)
        .map(({ productTypeKey, productTypeName }) => ({ text: productTypeName, value: productTypeKey }));
    },
    async loadShipVias() {
      this.loading = true;

      const params = {
        type: ManualShipViaType,
        country_state_code: this.countryStateCode,
        client_id: this.clientID,
        is_order_replacement: this.isReplacementOperation(),
        unit: this.patientUnit,
      };

      this.shipViaOptions = [];
      const res = await this.$store.dispatch('OrderManagement/getShipVias', params);
      if (res) {
        res.ship_vias.forEach(shipVia => {
          this.shipViaOptions.push({
            text: shipVia.name,
            value: shipVia.name,
          });

          if (shipVia.is_default) {
            this.order.ship_via = shipVia.name;
          }
        });
      }

      this.loading = false;
    },
    isReplacementOperation() {
      return this.$route.query.op === OPERATION_REPLACEMENT;
    },
    hasRequiredRouteParams() {
      return !!this.$route.params.clientID && !!this.$route.params.patientId;
    },
    hasReplaceOrderRouterParams() {
      return !!this.$route.params.orderID;
    },
    isSkuAvailable(sku) {
      return this.skuOptions.findIndex(item => item.value === sku) >= 0;
    },
    toggleShippingAddress() {
      this.order.add_shipping_address = !this.order.add_shipping_address;
      this.clearShippingAddressValues();
    },
    clearShippingAddressValues() {
      this.order.address_line_1 = null;
      this.order.address_line_2 = null;
      this.order.zip_code = null;
      this.order.city = null;
      this.order.state = null;
      this.order.country = null;
    },
    changeCountry() {
      this.order.state = null;
      this.showStates = false;
      // Only show states when US is selected
      if (this.order.country === 'US') {
        this.showStates = true;
      }
    },
    validateShippingAddressFields() {
      if (!this.order.add_shipping_address) {
        // In this case, the shipping address fields are not required
        return true;
      }

      const requiredShippingAddresFields = [
        this.order.address_line_1,
        this.order.zip_code,
        this.order.city,
        this.order.country,
      ];

      if (this.order.country === 'US') {
        requiredShippingAddresFields.push(this.order.state);
      }

      const failed = requiredShippingAddresFields.some(field => field === undefined || field === null || field.length === 0);
      return !failed;
    },
    async getUser() {
      const patientData = await this.getPatient();
      if (!patientData) {
        return false;
      }

      return R.path([ 'profile' ], patientData);
    },
    async isPatientEligible(user) {
      this.isLoading = true;

      try {
        const eligibilityRequest = {
          member: {
            first_name: user.firstname,
            last_name: user.lastname,
            email: user.email,
            dob: user.birthdate?.replace(/-/g, ''),
          },
        };

        if (user.origin !== ORIGIN_PORTAL) {
          const hpi = user.health_plan_info || {};

          eligibilityRequest.member.id_type = 'MI';
          eligibilityRequest.member.identification = hpi.member_id || '';

          if (hpi.coverage_type === ELIGIBILITY_COVERAGE_TYPE_DEPENDENT) {
            eligibilityRequest.subscriber = {
              first_name: hpi.subscriber_first_name,
              last_name: hpi.subscriber_last_name,
              dob: hpi.subscriber_dob?.replace(/-/g, ''),
            };
          }
        }

        return await this.checkPatientEligibility(eligibilityRequest);
      } finally {
        this.isLoading = false;
      }
    },
    async getPatient() {
      try {
        const res = await axios.get(`/v1/patients/${this.patientId}`);
        return res.data;
      } catch (e) {
        if (R.path([ 'response', 'data', 'message' ], e)) {
          this.$noty.error(e.response.data.message);
        } else {
          this.$noty.error(R.propOr('Failed to retrieve patient information.', 'message', e));
        }

        return null;
      }
    },
    async checkPatientEligibility(eligibilityRequest) {
      try {
        const res = await axios.post(`v1/clients/${this.clientID}/eligibility/check`, eligibilityRequest);
        return res.data.data.eligible;
      } catch (e) {
        if (e.status === 406) {
          this.$noty.error('Member is not eligible');
          return false;
        }

        if (!e.data || !e.data.error_description) {
          this.$noty.error('Eligibility request verification failed');
          return false;
        }

        const errorDescription = e.data.error_description;

        let errorMsg = 'Request failed:\n';
        Object.keys(errorDescription).forEach(k => {
          errorDescription[k].forEach(msg => {
            errorMsg += `${msg}\n`;
          });
        });

        this.$noty.error(errorMsg);
      }
      return false;
    },
    async onOrderTypeChange() {
      this.isOrderTypeSparePart = this.order.type === OrderTypeSparePart;

      if (!this.isOrderTypeSparePart) {
        return;
      }

      this.clearSelectedOrder();

      this.order.therapy = ProductTypeSparePart;
      await this.getSparePartsSkus();
    },
    async onCategoryChange() {
      this.clearSelectedSparePart();

      this.sparePartOptions = this.sparePartsSKUs
        .filter(sparePart => sparePart.category === this.order.category)
        .map(item => ({ text: item.description, value: item.sku }));
    },
    async onSparePartChange() {
      this.order.sku = this.order.spare_part;

      if (this.isReplacementOperation()) {
        this.skuOptions = [ { text: this.order.sku, value: this.order.sku } ];
      }
    },
    async getSparePartsSkus() {
      try {
        const params = {
          productType: ProductTypeSparePart,
          query: { exclude_attributes: [ 'semi_kit' ] },
        };
        const skus = await this.$store.dispatch('CSM/getSkusByProductType', params);

        if (!skus) {
          return;
        }

        this.sparePartsSKUs = skus
          .map(item => ({ category: item.sku_category.key, description: item.description, sku: item.sku }));

        this.categoryOptions = Array
          .from(new Set(this.sparePartsSKUs.map(item => (JSON.stringify({
            text: translations.orders.spare_parts.category[item.category] || item.category,
            value: item.category,
          })))))
          .map(item => JSON.parse(item));
      } catch (err) {
        this.$noty.error('Unable to get the spare parts skus');
      }
    },
    clearSelectedOrder() {
      this.order.therapy = null;
      this.clearSelectedSparePart();
    },
    clearSelectedSparePart() {
      this.skuOptions = [];
      this.order.sku = '';
      this.sparePartOptions = [];
      this.order.spare_part = null;
    },
    mapUnit(unit) {
      // Map legacy unit "sword" to "dpt"
      if (unit === UNIT_SWORD) {
        return UNIT_DPT;
      }
      return unit;
    },
  },
  async created() {
    if (!this.hasRequiredRouteParams()) {
      this.$router.go(-1);
      return;
    }

    if (this.isReplacementOperation() && !this.hasReplaceOrderRouterParams()) {
      this.$router.go(-1);
      return;
    }


    this.order.type = OrderTypeKit;
    this.patientId = this.$route.params.patientId;
    this.clientID = this.$route.params.clientID;
    this.countryStateCode = this.$route.params.countryStateCode;

    if (await this.isOrderManagementDisable(this.clientID)) {
      this.$noty.error(`Order creation is disabled for this client ID ${this.clientID}`);
      this.$router.go(-1);
      return;
    }

    this.user = await this.getUser();
    this.isEligible = await this.isPatientEligible(this.user);
    this.patientUnit = this.mapUnit(this.user.unit);

    this.orderTypeOptions = [
      { text: translations.orders.component_type.kit, value: OrderTypeKit },
      { text: translations.orders.component_type.spare_part, value: OrderTypeSparePart },
    ];

    this.reasonTypeOptions = [
      { text: 'Tablet Does not Turn On', value: 'tablet_does_not_turn_on' },
      { text: 'Tablet Touchscreen issue', value: 'tablet_touchscreen_issue' },
      { text: 'Tablet WiFi connectivity issue', value: 'tablet_wifi_connectivity_issue' },
      { text: 'Motion Tracker pairing', value: 'motion_tracker_pairing' },
      { text: 'Hardware', value: 'hardware' },
      { text: 'Software', value: 'software' },
      { text: 'Transportation Damage', value: 'transportation_damage' },
      { text: 'Kit Missing Parts', value: 'kit_missing_parts' },
      { text: 'Kit Delayed', value: 'kit_delayed' },
      { text: 'Kit Lost', value: 'kit_lost' },
      { text: 'Assistance', value: 'assistance' },
      { text: 'Switching', value: 'switching' },
      { text: 'Other', value: 'other' },
    ];

    this.therapyTypeOptions = await this.getProductTypes();
    await this.loadShipVias();

    if (this.isReplacementOperation()) {
      this.order.replaced_order_id = this.$route.params.orderID;
      this.order.therapy = this.$route.params.productType;

      this.orderTypeOptions = [
        {
          text: translations.orders.component_type[this.$route.params.componentType],
          value: this.$route.params.componentType,
        },
      ];

      this.order.type = this.$route.params.componentType;

      if (this.$route.params.componentType === OrderTypeSparePart) {
        await this.onOrderTypeChange();
        return;
      }

      await this.onChange(this.order.therapy, this.$route.params.sku);
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/OrderManagement.scss';
</style>
