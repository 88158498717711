import { render, staticRenderFns } from "./CreateManualOrder.vue?vue&type=template&id=125b2c98"
import script from "./CreateManualOrder.vue?vue&type=script&lang=js"
export * from "./CreateManualOrder.vue?vue&type=script&lang=js"
import style0 from "./CreateManualOrder.vue?vue&type=style&index=0&id=125b2c98&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports